:root {
    --gray_600:#717171;
    --gray_400:#b5b5b5;
    --blue_800:#2463bc;
    --gray_900:#212121;
    --deep_purple_400:#7f61db;
    --gray_200:#e9e9e9;
    --black_900_0c:#0000000c;
    --gray_50:#fafafa;
    --gray_100:#edf3fa;
    --teal_300:#5599b7;
    --indigo_400:#5971cb;
    --black_900:#000000;
    --deep_orange_400:#e58938;
    --white_A700:#ffffff;
}
