body {
    background-color: #fafafa;
}

a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
}

body {
    margin: 0;
    display: flex;
    /* place-items: center; */
    min-width: 320px;
    min-height: 100vh;
}

h1 {
    font-size: 3.2em;
    line-height: 1.1;
}

button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
}
span {
    font-family: Inter;
    color: black;
}
div {
    font-family: Inter;
}

.p-chart {
    min-height: 500px; /* or any desired height */
}

@media (prefers-color-scheme: light) {
    :root {
        color: #213547;
        background-color: #ffffff;
    }
    /* a:hover {
        color: #747bff;
    } */
    button {
        background-color: #f9f9f9;
    }
}


