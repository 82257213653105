
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer primereact


:root {
    --primary-color: #fafafa;
}
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    --tw-ring-color: var(--primary-color);
    border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input{
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
}
input:focus, textarea:focus{
    outline: none !important;
    outline-offset: 0 !important;
    --tw-ring-offset-width:none !important;
	border-color: #8899fc;
}

li:focus-visible{
    outline: none;
}

li:hover{
    cursor: pointer;
}




