* {
    box-sizing: border-box;
}
.custom-sidebar .p-sidebar-header {
    padding: 0;
    margin: 0;
}

.pro-sidebar {
    height: 100vh !important;
}
body {
    font-family: 'Inter', 'Roboto', sans-serif !important;
    margin: 0;
    padding: 0;
}
.dhiwise-code,
.dhiwise-navigation {
    min-height: 100%;
}
.common-pointer {
    cursor: pointer;
}
.dhiwise-navigation {
    padding: 20px;
    background-color: #232323;
}
.dhiwise-navigation h1 {
    color: white;
}
.dhiwise-navigation .headline {
    font-size: 14px;
    margin-bottom: 10px;
    color: white;
}
.dhiwise-navigation ul {
    padding: 0;
}
.dhiwise-navigation ul li {
    list-style: none;
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
}
.dhiwise-navigation a {
    color: #132cdc;
}
table {
    border-spacing: 0;
    width: 100%;
}
.common-select {
    z-index: 10000;
}
.react-datepicker-popper {
    z-index: 100000 !important;
}
.drawable-icon {
    position: absolute;
    margin: auto;
    z-index: 1000;
}
.input-wrap {
    position: relative;
}
option {
    color: #000;
}
.table-wrap {
    overflow: auto;
}
input:focus {
    outline: none;
}
.ReactModal__Body--open {
    overflow: hidden;
}
.slider-indicator:first-child {
    margin-left: 0;
}

.slider-indicator:last-child {
    margin-right: 0;
}

.rc-drawer-content-wrapper {
    width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper {
    display: flex;
    justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper {
    display: flex;
    justify-content: flex-start;
}
.ReactModal__Overlay {
    z-index: 1051;
}

ul li a {
    display: block;
}

.mobile-menu div {
    height: 1.5px;
    width: 20px;
    background-color: #000;
    margin: 4px 0;
}
.mobile-menu {
    display: none;
    cursor: pointer;
}
.header-row {
    align-items: center;
    justify-content: space-between;
    display: flex;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
    .mobile-menu {
        display: block;
    }
    .header-row {
        width: 100% !important;
    }
    .display-block {
        display: block;
    }
    .display-none {
        display: none;
    }
    .common-row-list > ul {
        padding-left: 10px;
        padding-right: 10px;
        flex-wrap: wrap;
    }
}
.menu-close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
}
.menu-overlay {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    position: fixed;
    left: 0px;
    top: 0px;
}
.close-line1 {
    transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.close-line2 {
    transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.common-row-list > ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    flex-grow: 1;
}

.common-column-list > ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

.common-column-list li {
    width: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #6b6b6b;
    border-radius: 10px;
}

/* Adjusting PrimeReact slide out animation for bottom modal to move to middle */
.p-dialog-mask.p-dialog-bottom {
    align-items: normal !important;
}
.p-dialog .p-dialog-default {
    margin-top: 3em;
    border-radius: 20px !important;
}
.p-dialog-content.custom-dialog {
    border-radius: 15px !important;
}

.p-dialog-content {
    padding: 0px !important;
}

.p-dialog-header {
    padding: 10px 20px 10px 20px !important;
}

/* Adjusting PrimeReact dropdown label and items to have new font colours and weights */
.p-dropdown-label {
    font-weight: 300;
}

.p-dropdown-item {
    font-weight: 300;
}

.p-placeholder {
    color: #727272;
    font-weight: 300;
}

.p-dropdown-label.p-inputtext {
    border: 0;
}

/* Adjusting PrimeReact tooltip component to use smaller font */
.p-tooltip {
    font-size: 12px;
}

/*Adjust PrimeReact Multiselect

/* Adjusting PrimeReact dropdown label and items to have new font colours and weights */
.p-multiselect-label.p-multiselect-items-label {
    font-weight: 300 !important;
    color: #727272;
}

.p-multiselect-item.p-highlight span {
    font-weight: 300 !important;
    color: #727272 !important;
}

.p-multiselect-item span {
    font-weight: 300 !important;
    color: #727272 !important;
}

.p-checkbox-box.p-highlight {
    border: 0;
}

.p-checkbox-box {
    border: 1px solid #727272;
}

.invalid-box {
    border-color: red;
}

.p-inputtext {
    border-radius: 8px;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-focus {
    border: 1px solid rgb(136, 153, 252);
    box-shadow: none;
}

.p-multiselect.p-component.p-inputwrapper.p-inputwrapper-focus {
    box-shadow: none;
    border: 1px solid rgb(136, 153, 252);
}
